import React, { useEffect, useState } from 'react'
import logo from '../images/shopersbay_logo.png'
import { useSelector, useDispatch } from 'react-redux'
import AuthAction from '../Redux/action/AuthAction';
import { Container, Row, Col, Card } from 'react-bootstrap'
import { FaCheckCircle } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { MdOutlineCircle } from "react-icons/md";
import { useNavigate, useSearchParams, useParams, NavLink } from 'react-router-dom';

const Module = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { moduleList } = AuthAction();
    const [searchParams] = useSearchParams();
    var param = searchParams.get('q');
    const { moduleData, selectedModules, calculation, vendorOrderData } = useSelector(state => state.authdetail)
    const [currency, setCurrency] = useState("")
    const [duration, setDuration] = useState("month")

    const handleChange = (e) => {
        const dura = e.target.checked ? 'annual' : 'month';
        setDuration(e.target.checked ? 'annual' : 'month');

        dispatch(moduleList({ vendor_id: 0, duration: dura, duration_type: "free_trial" }))

        dispatch({
            type: "setvendorOrderData",
            payload: { ...vendorOrderData, duration: dura }
        })

        const total = selectedModules.reduce((sum, { price }) => sum + parseFloat(price), 0).toFixed(2);
        const totalTax = selectedModules.reduce((sum, { price, tax_percent }) => sum + parseFloat(parseFloat(price) * parseFloat(tax_percent)) / 100, 0).toFixed(2);

        const grandTotal = selectedModules.reduce((sum, { price, tax_percent }) => {
            const numericPrice = parseFloat(price);
            const numericTaxPercent = parseFloat(tax_percent);
            const taxAmount = (numericPrice * numericTaxPercent) / 100;
            const totalForItem = numericPrice + taxAmount;
            return sum + totalForItem;
        }, 0);
        const grand = grandTotal.toFixed(2);

        dispatch({
            type: "setCalculation",
            payload: { ...calculation, total: total, tax: totalTax, grandTotal: grand }
        })

    }

    const handleCheckboxChange = (moduleData) => {

        const isModuleSelected = selectedModules.some((item) => item.id === moduleData.id);
        let updatedModules;

        if (isModuleSelected) {
            // Remove the module from the selected list
            updatedModules = selectedModules.filter((item) => item.id !== moduleData.id);
        } else {
            // Add the module to the selected list
            updatedModules = [...selectedModules, moduleData];
        }

        const selectedId = updatedModules.map(item => item.module);

        setCurrency(moduleData.currency)

        dispatch({
            type: "setSelectedModules",
            payload: updatedModules
        })

        const total = updatedModules.reduce((sum, { price }) => sum + parseFloat(price), 0).toFixed(2);
        const totalTax = updatedModules.reduce((sum, { price, tax_percent }) => sum + parseFloat(parseFloat(price) * parseFloat(tax_percent)) / 100, 0).toFixed(2);

        const grandTotal = updatedModules.reduce((sum, { price, tax_percent }) => {
            const numericPrice = parseFloat(price);
            const numericTaxPercent = parseFloat(tax_percent);
            const taxAmount = (numericPrice * numericTaxPercent) / 100;
            const totalForItem = numericPrice + taxAmount;
            return sum + totalForItem;
        }, 0);
        const grand = grandTotal.toFixed(2);

        const dataCalculated = { ...calculation, total: total, tax: totalTax, grandTotal: grand };

        dispatch({
            type: "setCalculation",
            payload: dataCalculated
        })

        dispatch({
            type: "setvendorOrderData",
            payload: { ...vendorOrderData, sub_total: total, tax: totalTax, total_amount: grand, selected_modules: selectedId.join(', '), item_count: selectedId.length, vendor_id: 0, duration: duration, duration_type: "free_trial" }
        })
    };

    const handleContine = () => {
        navigate(`/signup?q=${param}`);
    }

    useEffect(() => {
        dispatch(moduleList({ vendor_id: 0, duration: duration, duration_type: "free_trial" }))
    }, [dispatch])


    return (
        <div className="module-section">
            <Container>
                <Row>
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                        <Card className="border-0 p-4 parent-card">
                            <div className='mb-2'>
                                <NavLink to="https://shopersbay.com/"><img src={logo} alt="" /></NavLink>
                            </div>
                            <h5 className="mb-4">Get Started with your 15-day free trial.</h5>
                            <Row style={{ minHeight: Object.keys(moduleData).length > 0 ? "" : "350px" }}>
                                <div className={`${selectedModules.length > 0 ? "col-lg-8" : "col-lg-12"}`}>
                                    <div className="row">
                                        {moduleData.length > 0 &&
                                            moduleData.map((ele, i) =>
                                                ele.child_list.map((data, ind) => {
                                                    const isSelected = selectedModules.some((item) => item.id === data.id); // Correct check
                                                    return (
                                                        <label className="col-lg-6 col-md-6 col-6 form-label" key={`${i}-${ind}`}>
                                                            <div
                                                                className={`card d-flex w-100 align-items-center flex-row p-3 ${isSelected ? "selected" : ""
                                                                    }`}
                                                                onClick={() => handleCheckboxChange(data)}
                                                            >
                                                                <div className="module_name mx-2 flex-grow-1">
                                                                    <h6>{data.module_label}</h6>
                                                                </div>
                                                                <div className="checkbox-circle">
                                                                    {isSelected ? (
                                                                        <FaCheckCircle fill="#00A1F1" size={20} />
                                                                    ) : (
                                                                        <MdOutlineCircle fill="#ADB5BD" size={22} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </label>
                                                    );
                                                })
                                            )}
                                    </div>
                                </div>
                                {selectedModules.length > 0 && (
                                    <div className="col-lg-4">
                                        <div className="card border-0 animated-card">
                                            <div className="card-body">
                                                <div className='toggle-section mb-3'>
                                                    <div className="ot-switcher ">
                                                        <span className="l-switch">Monthly</span>
                                                        <label className={`switch form-check-label`} htmlFor='toggleMonth'>
                                                            <input type="checkbox" id="toggleMonth" onChange={handleChange} style={{ opacity: 0, border: '1px solid red' }} />
                                                            <span className={`slider round activebg ${duration === 'annual' ? 'activeclass activebg' : 'inactiveclass inactivebg'}`}></span>
                                                        </label>
                                                        <span className="r-switch">Annually</span>
                                                    </div>
                                                </div>
                                                <h6 className="">
                                                    <b className="me-1">{selectedModules.length}</b>
                                                    <span className="">
                                                        {selectedModules.length > 1 ? "Apps" : "App"} Selected
                                                    </span>
                                                </h6>
                                                <ul className="list-unstyled mb-3">
                                                    {selectedModules.map((module, idx) => (
                                                        <li key={idx} className="d-flex justify-content-between">
                                                            <div><FaCheck fill="#00A1F1" size={10} /> {module.module_label}</div><div>{module.currency} {(parseFloat(module.price) + (parseFloat(module.price) * module.tax_percent / 100)).toFixed(2)}</div>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <div className='mb-3 small'>
                                                    <Row>
                                                        <Col lg={6}>Subtotal</Col><Col lg={6} className='text-end'>{currency}{calculation.total}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={6}>Total Tax</Col><Col lg={6} className='text-end'>{currency}{calculation.tax}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={6}>Total</Col><Col lg={6} className='text-end'>{currency}{calculation.grandTotal} </Col>
                                                    </Row>

                                                </div>
                                                <div className='text-end'>
                                                    <button type="submit" className="octf-btn" onClick={() => handleContine()}>
                                                        Continue
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Row>
                            <div className='text-center'>
                                <span className='account-text'>Already Have account?</span> <span><NavLink className="text-decoration-none redirect-text" to={`/${param}`} >Sign In</NavLink></span>
                            </div>
                        </Card>
                    </div>
                    <div className="col-lg-2"></div>
                </Row>

            </Container>
        </div>

    )
}

export default Module
