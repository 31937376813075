import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FirstStepForm } from "../../validations/FormValidationRules";
import useFormvalidation from '../../validations/FormValidation';
import AuthAction from '../../Redux/action/AuthAction';
import { NavLink, useSearchParams  } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { FaRegEye, FaRegEyeSlash  } from "react-icons/fa";
import {Form} from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive';


const SignupForm = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const dispatch = useDispatch()
    const { varifyEmail, varifyOtppassword } = AuthAction();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isChecked, setIsChecked] = useState(true);

    const company_size = ["< 5 employees", "5-20 employees", "20-50 employees", "50-250 employees", "> 250 employees"];

    const { firstStepForm, formData, verificationStatus, checkStatus, otp_msz, otp_status, OtpStatus, source, loaderstatus } = useSelector(state => state.authdetail);

    const { values, errors, handleChange, setErrors, handleSubmit } = useFormvalidation(
        handleNext,
        FirstStepForm,
        firstStepForm
    );

    useEffect(() => {
        if (verificationStatus != undefined) {
          if (Object.keys(verificationStatus).length > 0) {
            setErrors({
              email: verificationStatus.message
            })
          } else {
    
          }
    
        }

        if (OtpStatus != undefined) {
          if (Object.keys(OtpStatus).length > 0) {
            setErrors({
              otp: OtpStatus.message
            })
          } else {
    
          }
    
        }
    
    }, [verificationStatus, OtpStatus])

    const [searchParams] = useSearchParams();

    var param = searchParams.get('q');
 
    function handleNext() {
      
        if(checkStatus == 0){
          dispatch({
              type: 'setfirstStepForm',
              payload: values
          });
    
          var input_data = {
              email: values.signupemail,
              password : values.password,
              company_name: values.company_name,
              platform: 1,
          }
    
          dispatch(varifyEmail(input_data))

        }else if(checkStatus == 1){
          var input_data1 = {
            email: formData.email,
            otp: parseInt(values.otp),
            id:formData.id,
          }
          dispatch(varifyOtppassword(input_data1, param))
        }
    
    }

    const togglePassword = () => {
      setPasswordVisible(prevState => !prevState);
    };

    useEffect(() => {
      dispatch({
        type: 'setfirstStepForm',
        payload: { ...firstStepForm, terms_policy : isChecked ? 1 : 0 }
      });
    }, [checkStatus])
    

  return (
    <div className="main-form">
      
      {checkStatus == 0 && 
          <>
          
          <div className='row mb-4'>
            <div className={`col-lg-6 col-md-12 ${isMobile ? "mb-4" : ""}`}>
              <input type="text" size="40" aria-required="true" name="cust_name" placeholder="First and Last Name*" onChange={handleChange} />
              {errors.hasOwnProperty('cust_name') && <span className='error'>{errors.cust_name}</span>}
            </div>
            <div className='col-lg-6 col-md-12'>
            <div class="form-group d-flex">
              <input className="form-control border-end country-code " name="countryCode" placeholder='+91' onChange={handleChange} />
              <input type="text" className="form-control phone-textbox" id="phone" name="contact" aria-describedby="emailHelp" placeholder="Phone Number*" onChange={handleChange} />
            </div>
              {/* <input type="text" size="40" aria-required="true" name="phone" placeholder="Phone Number" onChange={handleChange} defaultValue={firstStepForm.phone} /> */}
              {errors.hasOwnProperty('countryCode') && <><span className='error'>{errors.countryCode}</span><br/></>}
              {errors.hasOwnProperty('contact') && <span className='error'>{errors.contact}</span>}
            </div>
          </div>
          <div className='row mb-4'>
            <div className={`col-lg-6 col-md-12 ${isMobile ? "mb-4" : ""}`}>
              <input type="email" size="40" aria-required="true" name="signupemail" placeholder="Email*" onChange={handleChange} />
              {errors.hasOwnProperty('signupemail') && <span className='error'>{errors.signupemail}</span>}
            </div>
            <div className='col-lg-6 col-md-12'>
              <div className='password-div'>
                <input type={passwordVisible ? 'text' : 'password'} size="40" aria-required="true" name="password" placeholder="Password*" onChange={handleChange} />
                {passwordVisible ? 
                    <FaRegEye onClick={togglePassword} />
                    : <FaRegEyeSlash onClick={togglePassword} />
                    }
              </div>  
              {errors.hasOwnProperty('password') && <span className='error'>{errors.password}</span>}
            </div>
          </div>
          <div className='row mb-4'>
            <div className={`col-lg-6 col-md-12 ${isMobile ? "mb-4" : ""}`}>
              <input type="text" size="40" aria-required="true" name="company_name" placeholder="Company Name*" onChange={handleChange} />
              {errors.hasOwnProperty('company_name') && <span className='error'>{errors.company_name}</span>}
            </div>
            <div className='col-lg-6 col-md-12'>
              <Form.Select aria-label="Default select example" name="company_size" onChange={handleChange}>
                <option>Select Company size*</option>
                {
                  company_size.map((ele, i)=>{
                    return (
                      <option value={ele} key={i}>{ele}</option>
                    )
                  })
                }
              </Form.Select>
              
              {errors.hasOwnProperty('company_size') && <span className='error'>{errors.company_size}</span>}
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col'>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={isChecked} />
                <label className="form-check-label authorize-label" htmlFor="flexCheckChecked">
                By continuing you agree to our <NavLink to={`https://shopersbay.com/policies/privacy-policy`}>Privacy Policy</NavLink> and <NavLink to="https://shopersbay.com/policies/terms-of-use">Terms of Use</NavLink>
                </label>
              </div>
            </div>
          </div>

            <div className="mb-0 text-center"><button type="submit" className="octf-btn" onClick={handleSubmit}>Sign Up</button>
            </div>
            {/* {
            loaderstatus &&
            <div style={{ position: 'absolute', left: '79%', top: '67%', transform: 'translate(-50%, -50%)' }}>
              <Spinner animation="border" role="status" className='custom-spinners' size="sm" />
            </div>
            } */}
            <div className='space-20'></div>
           
            <div className='text-center'>
              <span className='account-text'>Already Have account?</span> <span><NavLink className="text-decoration-none redirect-text" to={`/${param}`} >Sign In</NavLink></span>
            </div>
       
          </>
        }
        {
          checkStatus == 1 && 
          <>
            <div className="mb-4">
                <input type="email" size="40" aria-required="true" name="otp" placeholder="Enter OTP *" onChange={handleChange} />
                {errors.hasOwnProperty('otp') && <span className='error'>{errors.otp}</span>}
            </div>
            
            <div className="mb-0 text-center"><button type="submit" className="octf-btn" onClick={handleSubmit}>Verify</button>
            {/* {loaderstatus && 
            <div style={{ position: 'absolute', left: '79%', top: '76%', transform: 'translate(-50%, -50%)' }}>
              <Spinner animation="border" role="status" className='custom-spinners' size="sm" />
            </div>
            } */}
            </div>
          </>
        }

      
    </div>
  )
}

export default SignupForm
