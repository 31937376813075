import React, { useState, useEffect } from 'react'
import { NavLink, useParams, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import shopersbayFavicon from '../../images/shopersbay_fav.png'
import shopdark from '../../images/shopersbay_logo.png'
import auditFavicon from '../../images/auditsbay-favicon.png'
import auditLogo from '../../images/auditsbay-logo.png'
import iraxFavicon from '../../images/irax-favicon.png'
import iraxLogo from '../../images/irax-logo.webp'
import iraLogo from '../../images/ira-logo.webp'
import iraFavicon from '../../images/ira-favicon.webp'
import SignupForm from './SignupForm'
import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPassword';
import { useMediaQuery } from 'react-responsive';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useSelector, useDispatch } from 'react-redux'

const AuthComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams()
  const dispatch = useDispatch()
  const [logo, setLogo] = useState("")
  const [favicon, setFavicon] = useState("")
  const [title, setTitle] = useState("")
  const [metatitle, setMetaTitle] = useState("")
  const [url, setUrl] = useState("")
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [searchParams] = useSearchParams();
  var param = searchParams.get('q');

  const handleBack = () => {
    navigate(-1);
  }

  useEffect(() => {
    dispatch({
      type: 'setSource',
      payload: slug
    });

    if (slug === "shopersbay" || param === "shopersbay" || location.pathname === '/') {

      setLogo(shopdark);
      setFavicon(shopersbayFavicon)
      setTitle("Shopersbay")
      setMetaTitle("Shopersbay - All-in-One")
      localStorage.setItem('ssids', '21e7ac68af78a3be93bbf71958f216d9')
    } else if (slug === "irax" || param === "irax") {
      setLogo(iraxLogo);
      setFavicon(iraxFavicon)
      setTitle("Irax")
      setMetaTitle("Your Technology Partner for Software Innovation and Market leading Solutions")
      localStorage.setItem('ssids', 'hk7ei9zazwf93uvhqcod65uyfpdu50fl')
    } else if (slug === "auditsbay" || param === "auditsbay") {
      setLogo(auditLogo);
      setFavicon(auditFavicon)
      setTitle("Auditsbay")
      setMetaTitle("Auditsbay")
      localStorage.setItem('ssids', 'hk7ei9zazwf93uvhqcod65uyfpdu50fl')
    } else if (slug === "ira" || param === "ira") {
      setLogo(iraLogo);
      setFavicon(iraFavicon)
      setTitle("Ira")
      setMetaTitle("Ira realty")
    }
  }, [slug])

  
  useEffect(() => {
    if (param === 'shopersbay') {
      setUrl("https://shopersbay.com/")
    }

  }, [param])

  return (
    <>

      <section className="finance-banner" >

        <div className="container-fluid">
          <div className="row finance-banner-row" >
            <div className={[
              (location.pathname === '/signup' ) && 'col-lg-3',

              (location.pathname === '/' || location.pathname === '/shopersbay' || location.pathname === '/auditsbay' || location.pathname === '/irax' || location.pathname === '/ira' || location.pathname === '/forgot-password') && 'col-xl-4 col-lg-4',
              
            ]
              .filter(Boolean)
              .join(' ')}>
              {!isMobile &&
                <div className='space-30'></div>
              }

            </div>
            <div className={[
              (location.pathname === '/signup') && 'col-lg-6',
              (location.pathname === '/' || location.pathname === '/shopersbay' || location.pathname === '/auditsbay' || location.pathname === '/irax' || location.pathname === '/ira' || location.pathname === '/forgot-password') && 'col-xl-4 col-lg-4',
              'd-flex align-content-center',
              ]
              .filter(Boolean)
              .join(' ')}>
                
              <div className={`finance-quote-form ${(location.pathname === '/' || location.pathname === '/shopersbay' || location.pathname === '/auditsbay' || location.pathname === '/irax' || location.pathname === '/ira' || location.pathname === '/forgot-password') ? "padding-login" : ""}`}>
                {location.pathname === '/signup' &&
                  <div className='back-module' onClick={handleBack}><FaArrowLeftLong size={22} /></div>  
                }
                {location.pathname !== '/forgot-password' ?
                  <>
                  {(location.pathname === '/' || location.pathname === '/shopersbay' || location.pathname === '/auditsbay' || location.pathname === '/irax' || location.pathname === '/ira') &&
                    <div>
                      <NavLink to="https://shopersbay.com/"><img src={logo} alt=""/></NavLink>
                    </div>
                  }
                  {
                    (location.pathname === '/signup' || location.pathname === '/module')&&
                    <div>
                      <NavLink to="https://shopersbay.com/"><img src={shopdark} alt=""/></NavLink>
                    </div>
                  }
                    
                    {location.pathname === '/signup' &&
                      <>
                        
                        <h3>Get Started with your 15-day free trial.</h3>
                      </>
                    }
                  </>
                  :
                  <>
                    <span className="wlcome-text"></span><span className="shopersbay-text">Forgot Password</span>
                    <div className="space-20"></div>
                    <h4>Enter the email address</h4>
                  </>
                }
                <div className="space-20"></div>
                <div className="wpcf7-form main-contact-form ">
                  {location.pathname === '/signup' &&
                    <SignupForm />
                  }
                  {(location.pathname === '/' || location.pathname === '/shopersbay' || location.pathname === '/auditsbay' || location.pathname === '/irax' || location.pathname === '/ira') &&
                    <LoginForm />
                  }
                  {location.pathname === '/forgot-password' &&
                    <ForgotPassword />
                  }
                </div>
                <div className="space-20"></div>
                {/* {location.pathname === '/signup' &&
                  <p className="agree-text">By continuing you agree to our <NavLink to={`https://shopersbay.com/policies/privacy-policy`}>Privacy Policy</NavLink> and <NavLink to="https://shopersbay.com/policies/terms-of-use">Terms of Use</NavLink></p>
                } */}
              </div>
            </div>
            <div className={[
    (location.pathname === '/signup') && 'col-lg-3',
    (location.pathname === '/' || location.pathname === '/shopersbay' || location.pathname === '/auditsbay' || location.pathname === '/irax' || location.pathname === '/ira' || location.pathname === '/forgot-password') && 'col-xl-4 col-lg-4',
  ]
    .filter(Boolean)
    .join(' ')}>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AuthComponent
