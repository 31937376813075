import React, {useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FirstStepForm } from "../../validations/FormValidationRules";
import useFormvalidation from '../../validations/FormValidation';
import Spinner from 'react-bootstrap/Spinner';
import AuthAction from '../../Redux/action/AuthAction';
import { FaRegEye, FaRegEyeSlash  } from "react-icons/fa";
import {useSearchParams} from 'react-router-dom'

const UpdatePasswordComponent = () => {
    const dispatch = useDispatch()
    const {updatePassword} = AuthAction()
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [cpasswordVisible, setcPasswordVisible] = useState(false);
    const [errormsz, seterrormsz] = useState("");
    const {updateForm, loaderstatus, formData} = useSelector(state => state.authdetail);

    const [searchParams] = useSearchParams();

    var param = searchParams.get('q');
    //func to visibility of new password
    const togglePassword = () => {
        setPasswordVisible(prevState => !prevState);
    };
    //func to visibility of confirm password
    const confirmtogglePassword = () => {
        setcPasswordVisible(prevState => !prevState);
    };
    //func to call api    
    const handleNext = () => {
        if(values.newPassword === values.confirmPassword){
            const input_data={
                "verify_id": formData.id,
                "login_type": formData.type,
                "new_password": values.newPassword,
            }
            dispatch(updatePassword(input_data, param))
        }else{
            seterrormsz("Confirm Password doesn't match!")
        }
    }

    const { values, errors, handleChange, setErrors, handleSubmit } = useFormvalidation(
        handleNext,
        FirstStepForm,
        updateForm
    );

    return (
        <>
            <div className="mb-4">
                <div className='password-div'>
                    <input type={passwordVisible ? 'text' : 'password'} size="40" aria-required="true" name="newPassword" placeholder="New Password" onChange={handleChange} />
                    {passwordVisible ? 
                    <FaRegEye onClick={togglePassword} />
                     : <FaRegEyeSlash onClick={togglePassword} />
                    }
                    
                </div>
                {errors.hasOwnProperty('newPassword') && <span className='error'>{errors.newPassword}</span>}
            </div>
            <div className="mb-4">
                <div className='password-div'>
                    <input type={cpasswordVisible ? 'text' : 'password'} size="40" aria-required="true" name="confirmPassword" placeholder="Confirm Password" onChange={handleChange} />
                    {cpasswordVisible ? 
                    <FaRegEye onClick={confirmtogglePassword} />
                     : <FaRegEyeSlash onClick={confirmtogglePassword} />
                    }
                </div>
                {errors.hasOwnProperty('confirmPassword') && <span className='error'>{errors.confirmPassword}</span>}
            </div>
            {errormsz != "" && 
            <>
            <div className='error'>{errormsz}</div>
            <div className='space-5'></div>
            </>
          }
            <div className="mb-0 text-center" /* style={{ position: "relative",pointerEvents:loaderstatus && 'none' }} */><button type="submit" className="octf-btn" onClick={handleSubmit}>Update</button>
            </div>
          
        </>
    )
}

export default UpdatePasswordComponent
