import React, {Suspense, lazy} from 'react'
import { Route, Routes, BrowserRouter as Router, BrowserRouter } from 'react-router-dom'
import * as view from '../view';
import { ScrollToTop } from '../hooks';
import * as ROUTES from '../constant/routes'

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div className='loading-main'>
        </div>}>
        <Routes>
          <Route path={ROUTES.home} element={
            <ScrollToTop>
              <view.SignIn/>
            </ScrollToTop>
          }>   
          </Route>
          <Route
            element=
            {
              <ScrollToTop>
                <view.SignIn/>
              </ScrollToTop>
            }
            exact
            path={ROUTES.SignIn}
          />
          <Route
            element=
            {
              <ScrollToTop>
                <view.Module/>
              </ScrollToTop>
            }
            exact
            path={ROUTES.Module}
          />
          <Route
            element=
            {
              <ScrollToTop>
                <view.SignUp/>
              </ScrollToTop>
            }
            exact
            path={ROUTES.SignUp}
          />
          <Route
              element=
              {
                <ScrollToTop>
                  <view.ForgotPassword>
                  </view.ForgotPassword >
                </ScrollToTop>
              }
              exact
              path={ROUTES.forgotpass}
            />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default AppRouter
