import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FirstStepForm } from "../../validations/FormValidationRules";
import useFormvalidation from '../../validations/FormValidation';
import AuthAction from '../../Redux/action/AuthAction';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import CryptoJS from 'crypto-js';


const LoginForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { userlogin } = AuthAction();
  const { slug } = useParams()
  const { firstStepForm, loginError, loaderstatus } = useSelector(state => state.authdetail);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  

  const { values, errors, handleChange, setErrors, handleSubmit } = useFormvalidation(
    handleNext,
    FirstStepForm,
    firstStepForm
  );

  //redirect function according to parameter
  const handleRedirect = () => {
    if (location.pathname === '/') {
      // navigate(`/signup?q=shopersbay`);
      navigate(`/module?q=shopersbay`);
    } else {
      // navigate(`/signup?q=${slug}`);
      navigate(`/module?q=${slug}`);
    }
  }

  //function to call api
  function handleNext() {
    const expires = new Date();
    if (rememberMe) {
      const encrypted = CryptoJS.AES.encrypt(values.password, '21e7ac68af78a3be93bbf71958f216d9').toString();
      
      localStorage.setItem('email', values.email);
      localStorage.setItem('password', encrypted);
      localStorage.setItem('remember', 'checked');
      localStorage.setItem('expires', expires.toISOString());
    }
    var input_data = {
      username: values.email,
      password: values.password
    }
    dispatch(userlogin(input_data, slug))
    setTimeout(clearLoginError, 5000);
  }
  //function to redirect to forgot password
  const handleClick = () => {
    if (location.pathname === '/') {
      navigate(`/forgot-password?q=shopersbay`);
    } else {
      navigate(`/forgot-password?q=${slug}`);
    }
  }
  //function to visibility of password
  const togglePassword = () => {
    setPasswordVisible(prevState => !prevState);
  };

  const clearLoginError = () => {
    dispatch({
      type: 'setloginError',
      payload: "",
    });
  };



  useEffect(() => {

  }, [loginError])

  useEffect(() => {
    var getemail = localStorage.getItem("email")
    var getpassword = localStorage.getItem("password")
    var remember = localStorage.getItem("remember")
    if(getpassword != null){
      var bytes = CryptoJS.AES.decrypt(getpassword, '21e7ac68af78a3be93bbf71958f216d9');
      var decrypted = bytes.toString(CryptoJS.enc.Utf8);
    }
    dispatch({
      type: 'setfirstStepForm',
      payload: {
        email: getemail != null ? getemail : '',
        password: decrypted != null ? decrypted : '',
      },
    });
    if (remember != null) {
      setRememberMe(true)
    }
  }, [])

  return (
    <>

      <div className="main-form">
        <div className="mb-4">
          <input type="email" size="40" aria-required="true" name="email" placeholder="Email" onChange={handleChange} autoComplete="email" value={values.email} />
          {errors.hasOwnProperty('email') && <span className='error'>{errors.email}</span>}
        </div>
        <div className="mb-4">
          <div className='password-div'>
            <input type={passwordVisible ? 'text' : 'password'} size="40" aria-required="true" name="password" placeholder="Password" onChange={handleChange} autoComplete="current-password" value={values.password} />
            {passwordVisible ?
              <FaRegEye onClick={togglePassword} />
              : <FaRegEyeSlash onClick={togglePassword} />
            }
          </div>
          {errors.hasOwnProperty('password') && <span className='error'>{errors.password}</span>}
        </div>
        <div className="mb-4">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
            <label class="form-check-label" for="flexCheckDefault">
              Remember Me
            </label>
          </div>
        </div>
        {loginError !== "" &&
          <>
            <div className='space-5'></div>
            <div className='error'>{loginError}</div>
            <div className='space-5'></div>
          </>
        }

        <div className="mb-0 text-center ">
        <button type="submit" className="octf-btn" onClick={handleSubmit}>Sign In
        </button>
        </div>
        
        <div className='space-20'></div>

        <div className='sign-in-section'>
          <span className='account-text'>Don't have account?</span> <span><a className="text-decoration-none redirect-text" onClick={handleRedirect} >Sign Up</a></span>
        </div>
        <div className='space-10'></div>
        <div className='forgot-password'>
          <span onClick={handleClick}>Forgot Password?</span>
        </div>

      </div>
    </>
  )
}

export default LoginForm
