import React, {useEffect} from 'react'
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

const OtpComponent = ({...props}) => {
    // Extracting specific props you might want to use directly
    const { errors, onchange, handlesubmit, values, setErrors } = props;
    
    const {loaderstatus, OtpStatus}=useSelector(state => state.authdetail);

    useEffect(() => {
      if (OtpStatus != undefined) {
        if (Object.keys(OtpStatus).length > 0) {
          setErrors({
            otp: OtpStatus.message
          })
        }
      }
    },[OtpStatus])

  return (
    <>
      <div className="mb-4">
                <input type="email" size="40" aria-required="true" name="otp" placeholder="Enter OTP *" onChange={onchange} value={values}/>
                {errors.hasOwnProperty('otp') && <span className='error'>{errors.otp}</span>}
            </div>
            
            <div className="mb-0 text-center"><button type="submit" className="octf-btn" onClick={handlesubmit}>Verify</button>
    
            </div>
    </>
  )
}

export default OtpComponent
