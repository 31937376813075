import React from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { NavLink, useParams, useLocation, useSearchParams } from 'react-router-dom';

const Apis = () => {
  const vendorToken = localStorage.getItem("ssids")

  axios.defaults.baseURL = "https://admin.shopersbay.com/";
  // axios.defaults.baseURL = "http://localhost/admin.apanashopee.in/";
  
  const cookies = new Cookies()

  axios.defaults.headers.common['authentication'] = vendorToken;

  const saveCustomer = async (input_data) => {
    const response = await axios.post("asapi/register", input_data);
    return response;
  }

  const LoginService = async (input_data) => {
    const response = await axios.post("asapi/authlogin", input_data);
    return response;
  };

  const checkEmail = async (input_data) => {
    const response = await axios.post("asapi/varifyMailandPhone", input_data);
    return response;
  };

  const verifyotpService = async (input_data) => {
    const response = await axios.post("asapi/varifyOtp_new", input_data);
    return response;
  };

  const forgotPasswordApi = async (input_data) => {
    const response = await axios.post("asapi/authForgetPassword", input_data);
    return response;
  };

  const updatePasswordApi = async (input_data) => {
    const response = await axios.post("asapi/updateAuthPassword", input_data);
    return response;
  };

  const vendorCreation = async (input_data) => {
    const response = await axios.post("asapi/createVendor", input_data);
    return response;
  };

  const getCountryCode = async (input_data) => {
    const response = await axios.post("asapi/getCountryCode", input_data);
    return response
  }

  const getModuleList = async (input_data) => {
    const response = await axios.post("asapi/getModuleList", input_data);
    return response
  }
  const createVendorOrder = async (input_data) => {
    const response = await axios.post("asapi/createVendorOrder", input_data);
    return response
  }


  return {
    saveCustomer: saveCustomer,
    LoginService: LoginService,
    checkEmail: checkEmail,
    verifyotpService: verifyotpService,
    forgotPasswordApi: forgotPasswordApi,
    updatePasswordApi: updatePasswordApi,
    vendorCreation: vendorCreation,
    getCountryCode: getCountryCode,
    getModuleList: getModuleList,
    createVendorOrder: createVendorOrder,
  }
}

export default Apis
